import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { Company, CompanyUser, Project } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { UtilService } from '../../services/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
interface ParentData {
  pageFrom?: 'partyEarning'| 'party-payment' | 'materialPurchase'| 'materialInventory' | 'withdraw'| 'deposit' | 'attendance' | 'project-setting' | 'sales-invoice' | 'AddQuotationComponent' | 'equipment' | 'warehouse-setting' | 'create-subcon'| 'purchase-order' | 'material-return' | 'task-assignee' | 'project-creation' | 'asset-modal' | 'todo';
  project_id?: string;
  party_company_user_id?: string;
  autoFocus?: boolean;
  autoClick?: boolean;
  inputLabel?: string;
  hideWarehouse?: boolean;
}
@Component({
  selector: 'app-project-input',
  templateUrl: './project-input.component.html',
  styleUrls: ['./project-input.component.css']
})
export class ProjectInputComponent implements  OnInit, OnDestroy  {
  @ViewChild('projectName') projectNameEle: ElementRef;
  @Input() parentComponent = {} as ParentData;
  @Output() onCompanyProjectSelect = new EventEmitter<Project>(true);
  @Input() required: boolean;
  public formInputError = '';
  public companyProject = {} as Project;
  public companyProjects = [] as Project[];
  
  public company = {} as Company;
  public companyProjectSearchBox = false;
  isWarehouse = true;
  mobileForm: FormGroup;
  mobileNumber: string;
  constructor(
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    public authService:AuthService,
    private utilService: UtilService
  ) {
  }
  
  ngOnInit(): void {
    this.company = this.authService.session.company || {};
    this.getProjects();
    this.isWarehouse = this.parentComponent.hideWarehouse ? false : true;
  
  }

  ngAfterViewInit() {
    if(this.parentComponent?.autoFocus){
      setTimeout(() => {
        this.projectNameEle.nativeElement?.focus();
      }, 100);
    }
  }
  
  ngOnDestroy() {
    //
  }
  
  getProjects() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.getProjectList(qp).subscribe((res) => {
      this.companyProjects = res.projects || ([] as Project[]);
      this.localStorageService.set('companyProjects', this.companyProjects);
      if(this.parentComponent.autoClick){
        this.projectNameEle.nativeElement?.click();
      }
      this.utilService.projectIdObservable.subscribe(party_company_project_id => {
        if (party_company_project_id) {
          this.companyProject = this.companyProjects.find(project => project.id == party_company_project_id);
          if(this.companyProject){
            this.setCompanyProject(this.companyProject);
          }else{
            this.companyProject = {}
            this.sendProject(this.companyProject)
          }
        }
      })
  
    });
  }
  
  filterCompanyProjects() {
    let len = 0;
    this.companyProject.name  = this.companyProject.name?.trimStart()
    this.companyProject.name ? len = this.companyProject.name?.length : 0;
  
    if (len > 0) {
      this.companyProjects = this.localStorageService.get('companyProjects') || [];
      const pattern = new RegExp(this.companyProject.name, 'i');
      this.companyProjects = this.companyProjects.filter((f) =>
        f.name.match(pattern)
      );
      this.companyProjectSearchBox = true;
    }
    if (len == 0) {
      this.companyProjects = this.localStorageService.get('companyProjects');
      this.companyProjectSearchBox = true;
    }
  }
  
  closeSearchBox(event?) {
    event?.stopPropagation();
    this.companyProject = {};
    this.filterCompanyProjects();
    this.companyProjectSearchBox = false;
    this.onCompanyProjectSelect.emit()
  }
  
  setCompanyProject(item: Project) {
    this.companyProject = item;
    this.formInputError = '';
    this.companyProjectSearchBox = false;
    this.sendProject(item);
  }
  
  checkName(companyProjectName: string) {
    const localProject = this.localStorageService.get('companyProjects') as Project[];
    const findProject = localProject.filter(item => item.name === companyProjectName);
    if (findProject.length <= 0) {
      // this.sendProject()
    } else {
      return this.companyProject;
    }
  }
  
  sendProject(item?: Project) {
    this.formInputError = '';
    this.onCompanyProjectSelect.emit(item)
  }
  setError(value: boolean) {
    value == true ? this.formInputError = 'projectName' : this.formInputError = '';
  }
  
  }

