import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import {HomeComponent} from './components/home/home.component';
import { CoreModule } from './core/core.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { FinancialsComponent } from './project-overview/components/financials/financials.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BillsComponent } from './project-overview/components/bills/bills.component';
import { ExpensesComponent } from './project-overview/components/expenses/expenses.component';
import { ScheduleComponent } from './project-overview/components/schedule/schedule.component';
import { OverviewPhotosComponent } from './project-overview/components/overview-photos/overview-photos.component';
import { AttendanceComponent } from './project-overview/components/attendance/attendance.component';
import { SharedModule } from "./shared/shared.module";
import { SalesInvoiceComponent } from './project-overview/components/sales-invoice/sales-invoice.component';
import { CategoryExpenseListComponent } from './project-overview/components/category-expense-list/category-expense-list.component';
import { counterReducer } from './purchase-order/store/purchase-order.reducers';
import { AddEditDeductionComponent } from './deduction/add-edit-deduction/add-edit-deduction.component';
import { ViewDeductionComponent } from './deduction/view-deduction/view-deduction.component';
import { NewItemDeductionComponent } from './deduction/new-item-deduction/new-item-deduction.component';
import { AddDebitNoteComponent } from './debit-note/components/add-debit-note/add-debit-note.component';
import { ViewDebitNoteComponent } from './debit-note/components/view-debit-note/view-debit-note.component';
import { AddDebiteNoteItemComponent } from './debit-note/components/add-debite-note-item/add-debite-note-item.component';

const config: SocketIoConfig = { url: 'https://testweb.onsiteteams.com', options: {} };

// const config: SocketIoConfig = { url: 'http://localhost:4000', options: {} };

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ProjectOverviewComponent,
        FinancialsComponent,
        BillsComponent,
        ExpensesComponent,
        ScheduleComponent,
        OverviewPhotosComponent,
        AttendanceComponent,
        SalesInvoiceComponent,
        CategoryExpenseListComponent,
        AddEditDeductionComponent,
        ViewDeductionComponent,
        NewItemDeductionComponent,
        AddDebitNoteComponent,
        ViewDebitNoteComponent,
        AddDebiteNoteItemComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        CoreModule,
        SocketIoModule.forRoot(config),
        AppRoutingModule,
        InfiniteScrollModule,
        NgbModule,
        NgApexchartsModule,
        SharedModule,
        StoreModule.forRoot({ company: counterReducer }),
        StoreDevtoolsModule.instrument({
        maxAge: 25
        }),
    ]
})
export class AppModule { }
