<!-- <ng-container *ngIf="isNotPaid"> -->

<!-- <div class="close-btn">Close</div> -->
<div *ngIf="isNotPaid"
  style="background-color: rgba(0, 0, 0, 0.371); height: 100%; width: 100%; position: fixed; top: 0; left: 0;">
</div>
<div class="unpaid" [ngClass]="isNotPaid?'bottom-f-state':'bottom-i-state'">

  <div class="unpaid-content">
    <div class="d-flex flex-column mt-5 gap-4">
      <div>
        <div>Buy Premium account for</div>
        <div (click)="openMenu = !openMenu" class="dropdown">
          <div class="select" [ngClass]="{'select-clicked':openMenu}">
            <div class="selected f-22">{{activeTab?activeTab:company.name | uppercase}}</div>
            <div class="caret" [ngClass]="{'caret-rotate':openMenu}" ></div>
          </div>
          <ul class="menu" [ngClass]="{'menu-open':openMenu}">
            <ng-container *ngFor="let item of authService.session.companies">
              <li (click)="activeTab = item.name; selectCompany(item)" [ngClass]="{'active':  !activeTab?company.name === item.name:activeTab === item.name}">{{item.name}}</li>
            </ng-container>
          </ul>
        </div>

      </div>
      <div class="d-flex flex-column" style="font-weight: 300; font-size: 16px; line-height: 24px; color: #000000;">
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Access to Onsite Web
          </span>
        </div>
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Combined Party Balances for All Projects
          </span>
        </div>
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#EF8A17" />
            <path d="M12.1673 5.75L7.12565 10.7917L4.83398 8.5" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="ms-4">
            Download Excel Reports
          </span>
        </div>
      </div>
      <div class="hstack justify-content-between" style="flex-wrap: wrap;">
        <div *ngIf="!trialExhausted" class="start-trial-btn text-center" (click)="trialSubcription();">
          <span class="start-trial-title">Start Trial</span>
          <span class="start-trial-subtitle"> (2 Days)</span>
        </div>
        <div *ngIf="trialExhausted" class="start-trial-btn text-center">
          <span class="start-trial-title">Trial exhausted</span>
          <!-- <span class="start-trial-subtitle"> (15 Days)</span> -->
        </div>
        <!-- <div class="upgrade-btn" (click)="navigateTo()">
          <span class="upgrade-title">Upgrade</span>
          <span class="upgrade-subtitle"> (To Premium)</span>
        </div> -->
      </div>

      <div class="d-flex gap-3 align-items-center" style="font-weight: 400;font-size: 15px;">
        <div>1,00,000+</div>
        <div>Happy customers</div>
        <div style="border-left: dotted 1px #000;" class="ps-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21.9994 16.9201V19.9201C22.0006 20.1986 21.9435 20.4743 21.832 20.7294C21.7204 20.9846 21.5567 21.2137 21.3515 21.402C21.1463 21.5902 20.904 21.7336 20.6402 21.8228C20.3764 21.912 20.0968 21.9452 19.8194 21.9201C16.7423 21.5857 13.7864 20.5342 11.1894 18.8501C8.77327 17.3148 6.72478 15.2663 5.18945 12.8501C3.49942 10.2413 2.44769 7.27109 2.11944 4.1801C2.09446 3.90356 2.12732 3.62486 2.21595 3.36172C2.30457 3.09859 2.44702 2.85679 2.63421 2.65172C2.82141 2.44665 3.04925 2.28281 3.30324 2.17062C3.55722 2.05843 3.83179 2.00036 4.10945 2.0001H7.10945C7.59475 1.99532 8.06524 2.16718 8.43321 2.48363C8.80118 2.80008 9.04152 3.23954 9.10944 3.7201C9.23607 4.68016 9.47089 5.62282 9.80945 6.5301C9.94399 6.88802 9.97311 7.27701 9.89335 7.65098C9.8136 8.02494 9.62831 8.36821 9.35944 8.6401L8.08945 9.9101C9.513 12.4136 11.5859 14.4865 14.0894 15.9101L15.3594 14.6401C15.6313 14.3712 15.9746 14.1859 16.3486 14.1062C16.7225 14.0264 17.1115 14.0556 17.4694 14.1901C18.3767 14.5286 19.3194 14.7635 20.2794 14.8901C20.7652 14.9586 21.2088 15.2033 21.526 15.5776C21.8431 15.9519 22.0116 16.4297 21.9994 16.9201Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="ps-2">
            Call us: 9560209605
          </span>
        </div>

        <div style="border-left: dotted 1px #000;" class="ps-2">
          <span class="ps-2 pointer" (click)="logout()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.36 6.64C19.6184 7.89879 20.4753 9.50244 20.8223 11.2482C21.1693 12.9939 20.9909 14.8034 20.3096 16.4478C19.6284 18.0921 18.4748 19.4976 16.9948 20.4864C15.5148 21.4752 13.7749 22.0029 11.995 22.0029C10.2151 22.0029 8.47515 21.4752 6.99517 20.4864C5.51519 19.4976 4.36164 18.0921 3.68036 16.4478C2.99909 14.8034 2.82069 12.9939 3.16772 11.2482C3.51475 9.50244 4.37162 7.89879 5.63 6.64" stroke="black"  stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 2V12" stroke="black"  stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
               <span class="ps-2">
                Logout
              </span>
          </span>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- </ng-container> -->
