<div class="canvas-height">
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-2">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-column align-items-start">
        <span class="f-12">Sales Invoice</span>

      </div>
    </div>

    <div class="d-flex justify-content-end gap-4">

      <button (click)="saveDeductionItem()" class=" btn f-12" type="button"
        style="border: none;  background-color: #fff; color: #000;"> Save </button>
    </div>
  </div>
  <div style="background: #5F44CE; height: 7px"></div>
  <div class="canvas-body p-4">
    <div class="custom-input">
      <label for="name">Item Name</label>
      <input [(ngModel)]="deductionItemName" type="text" class="form-control" id="name" name="name"
        placeholder="Enter Item Name">
    </div>
  </div>
</div>