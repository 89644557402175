<div class="p-0 canvas-height">
  <!-- <app-logo-loader [state]="loading"></app-logo-loader> -->
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div
      class="canvas-header-left d-flex align-items-center gap-4"
      style="width: 100%"
    >
      <app-back-button></app-back-button>
      <div class="canvas-title">
        <span style="font-weight: 700; font-size: 14px; line-height: 21px"
          >Add New Account</span
        >
      </div>
    </div>
    <div class="d-flex gap-4 ">
      <div class="canvas-cancel-btn" (click)="closeBankCanvas()">Cancel</div>
      <div class="canvas-save-btn" (click)="saveBankAccount()">Save</div>
    </div>
  </div>

  <div style="background: #8d7af0; height: 7px"></div>

  <div class="canvas-body">
    <div class="d-flex bank-info gap-3 mb-4">
      <div style="height:40px; width:40px; background-color: grey; border-radius: 5px;" class="bank-logo" *ngIf="bankLogoUrl">
          <ng-container>
            <img style="height:40px; width:40px; border-radius: 4px;" [src]="bankLogoUrl" onerror="this.src='https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/DEFAULT_LOGO.jpg'" alt="">
          </ng-container>
      </div>
      <div class="bank-info d-flex flex-column">
        <span class="f-14" >{{companyBank.monkey_patch_bank_account?.bank_name}}</span>
        <span class="f-12">{{companyBank.monkey_patch_bank_account?.bank_address}}</span>
      </div>
    </div>


    <form>
      <div class="custom-input mb-4">
        <label for="accountHolderName">Account Holder Name:</label>
        <input type="text" id="accountHolderName" name="accountHolderName" [(ngModel)]="companyBank.monkey_patch_bank_account.account_name">
      </div>

      <div class="custom-input mb-4" >
        <label for="accountNumber">Account Number:</label>
        <input type="text" id="accountNumber" name="accountNumber" [(ngModel)]="companyBank.monkey_patch_bank_account.account_number">
      </div>
      
      <div class="custom-input mb-4" >
        <label for="bankCode">{{authService.session.company.country_iso == 'in'?'IFSC Code':'Bank/Branch code'}}:</label>
        <input type="text" id="bankCode" name="bankCode" (ngModelChange)="onValidateBankcode($event)" [(ngModel)]="companyBank.monkey_patch_bank_account.bank_code" autocomplete="off" >
      </div>
      
      <div class="custom-input mb-4" >
        <label for="bankName">Bank Name:</label>
        <input type="text" id="bankName" name="bankName" [(ngModel)]="companyBank.monkey_patch_bank_account.bank_name">
      </div>

      <div class="custom-input mb-4" >
        <label for="bankAddress">Bank Address:</label>
        <input type="text" id="bankAddress" name="bankAddress" [(ngModel)]="companyBank.monkey_patch_bank_account.bank_address">
      </div>

      <div class="custom-input mb-4" >
        <label for="iBanNumber">Iban Number:</label>
        <input type="text" id="iBanNumber" name="iBanNumber" [(ngModel)]="companyBank.monkey_patch_bank_account.iban_number">
      </div>

      <div *ngIf="authService.session.country.country_iso === 'in'" class="custom-input" >
        <label for="upiNumber">UPI Number:</label>
        <input type="text" id="upiNumber" name="upiNumber" [(ngModel)]="companyBank.monkey_patch_bank_account.upi_ids[0]">
      </div>




    </form>
  </div>



</div>
