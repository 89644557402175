<div class="canvas-height">
    <div class="offcanvas-header d-flex align-items-center px-4">
        <div class="canvas-header-left d-flex align-items-center gap-4">
          <app-back-button></app-back-button>
          <div class="canvas-title d-flex flex-wrap flex-column">
            <span class="f-14">{{authService.session.country?.tax_display_name}} Details</span>
          </div>
        </div>
        <div class="d-flex gap-4 ">
          <div class="canvas-cancel-btn" (click)="ngbOffActiveCanvas.dismiss()">Cancel</div>
          <div (click)="save()" class="canvas-save-btn">Save</div>
        </div>
      </div>

      <div class="canvas-body d-flex flex-column gap-3">
       
        <div class="custom-input">
            <label for="gst">{{authService.session.country?.tax_display_name}}</label>
            <input [(ngModel)]="gst_details.gstin" id="gst" type="text">
            <div *ngIf="!validGst" style="position: absolute; right: 10px; top: 10px;" [ngbTooltip]="'Invalid ' +authService.session.country?.tax_display_name + ' Number'" placement="top">
                <ng-icon name="featherAlertCircle" style="transform: scale(1.1); color: #DC3545" class="disabled-font"></ng-icon>
            </div>
        </div>

        <div class="custom-input">
            <label for="buisness">
                Legal Business Name
            </label>
            <input [(ngModel)]="gst_details.legal_business_name" id="buisness" type="text">
        </div>

        <div class="custom-input">
            <label for="supply">
                State of Supply
            </label>

            <input [(ngModel)]="gst_details.state_of_supply" id="supply" type="text">
        </div>

        <div class="custom-input">
            <label for="billing">Billing Address</label>
            <input [(ngModel)]="gst_details.billing_address" id="biling" type="text">
        </div>

       


      </div>
</div>