import { Component } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { IAlert } from '../interfaces/interfaces';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public alerts: IAlert;
  showAlert = false;
  constructor(
    public authService: AuthService
  )
  {
    console.log('App Version: ', environment.appVersion);
    if(this.authService.session.token){
      this.authService.getUserByToken().subscribe((res) => {
        this.authService.saveProfile(res.user);
      });
    }
    this.authService.networkStatus().subscribe(status => {
      if (status === true) {
        this.alerts = {};
        this.showAlert = true;
        this.alerts = { id: 1, type: 'success', message: 'Welcome Online!' };
        setTimeout((() => {
          this.alerts = {};
          this.showAlert = false;
        }).bind(this), 5000);
      } else {
        this.alerts = {};
        this.showAlert = true
        this.alerts = { id: 1, type: 'danger', message: 'Network Connection Lost!' };
      }
    });
    window.addEventListener('load', () => {
      this.hidePlashScreen();
    });
  }
  
  ngAfterViewChecked() {
    if(document.readyState == 'complete') this.hidePlashScreen();
  }

  hidePlashScreen(){
    const splashElement = document.getElementById('splash');
    if (splashElement) {
      splashElement.style.display = 'none';
    }
  }

  closeAlert() {
    this.alerts = {}
    this.showAlert = false
  }
}
