import { Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { Profile, Project, Task, Company } from 'src/app/interfaces/interfaces';
import { AdminService } from 'src/app/core/services/admin.service';
import { CompanyService } from 'src/app/company/services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectComponent } from 'src/app/admin/components/modals/project/project.component';
import { UtilService } from '../../services/util.service';
import { SettingCompanyComponent } from '../modal/setting-company/setting-company.component';
import { environment } from 'src/environments/environment';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { SettingWarehouseComponent } from '../modal/setting-warehouse/setting-warehouse.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  @Input() config = { active: 'dashboard' } as any;
  public valueStack = { page: { next_url: '' } };
  public taskValueStack = { page: { next_url: '' } };
  public projects = [] as Project[];
  public pinnedProjects = [] as Project[];
  public companies = [] as Company[];
  public selectedCompany = {} as Company;
  public tasks = [] as Task[];
  public busy = false;
  public loading = false;
  public primaryProfile = {} as Profile;
  private paginate = 1;
  private limit = 10;
  // public showMenu = false;
  public app_version = '';
  public subscriptionLeft: number;
  public i_am_admin = true;
  private company = {} as Company;
  public show_warehouse = false;
  wordAfterD:string;
  public referralUrl = 'https://zfrmz.in/CgGKl1EzOwYbBuBalbMS';
  constructor(
    public authService: AuthService,
    public adminService: AdminService,
    public companyService: CompanyService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    public utilService: UtilService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute
  ) {
    this.app_version = environment.appVersion || '';
    this.selectedCompany = this.authService.session.company || {} as Company;
    // this.companyService.observeCompanies$.subscribe(res => this.companies = res || [] as Company[]);
    this.getWorkableCompanyList();
    this.company = this.authService.session.company;
    this.authService.companyListObserver.subscribe(res=>{
      if(res){
        this.companies = this.authService.session.companies || [] as Company[];
      }
    })

    const url = this.router.url;
    const regex = /\/d\/([^/]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      this.wordAfterD = match[1];
    }else{
      if (url.includes('/w/')) this.wordAfterD = 'warehouse';
      else this.wordAfterD = null;
    }

    authService.project$.subscribe(res=>{
      this.getPinnedProjects();
    })
  }

  getPinnedProjects(){
    this.pinnedProjects = this.localStorageService.get('pinnedProjects') || [] as Project[]
  }

  getWorkableCompanyList(){
    this.authService.getWorkableCompanyList().subscribe({
      next:(res)=>{
            this.companies = res?.Companies || [] as Company[];
            this.authService.session.companies = this.companies || [] as Company[];
      },
      error:(error)=>{
        console.log('error',error);
      }
    })
  }



  ngOnInit() {
    this.config.active = this.config.active || 'dashboard';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        const regex = /\/d\/([^/]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
          this.wordAfterD = match[1];
        } else {
          if (url.includes('/w/')) this.wordAfterD = 'warehouse';
          else this.wordAfterD = null;
        }
      }
    });
  }

  selectCompany(item: Company) {
    // this.showMenu = false;
    this.router.navigate([`/c/${item.id}/d/home`]);
  }

  // companyLevelNavigator(whereToGo: 'parties' | 'home' | 'report-list' | 'library' | 'dashboard' | 'quotation' | 'company-role' | 'warehouse' | 'purchase-order' | 'asset-management' | 'services' | 'company-settings') {
  //   if (whereToGo === 'library') {
  //     let subRoute = ""
  //     if (this.authService.hasAccess(this.authService.companyUserPolicy.View_material_library)) subRoute = "material"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_trade_library)) subRoute = "trade"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_party_library)) subRoute = "party"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_workforce_library)) subRoute = "workforce"
  //     if (subRoute != "") {
  //       this.router.navigate([`/c/${this.authService.session.company.id}/d/${whereToGo}/${subRoute}`], { queryParamsHandling: 'preserve' })
  //     }
  //   } else if (whereToGo == 'warehouse') {
  //     if (!this.authService.session.warehouse.id) {
  //       this.addWarehouse();
  //       return;
  //     } else {
  //       this.router.navigate([`/c/${this.authService.session.company.id}/w/${this.authService.session.warehouse?.id}`], { queryParamsHandling: 'preserve' });
  //     }
  //   } else if(whereToGo === 'company-settings'){
  //      let subRoute = ""
  //     if (this.authService.hasAccess(this.authService.companyUserPolicy.View_company_dashboard)) subRoute = "company-details"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_company_party_ledger)) subRoute = "business-overview"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_project_dashboard)) subRoute = "lock-bak-date"
  //     else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_Purchase_Order_List)) subRoute = "bank-details"
  //     if (subRoute != "") {
  //       this.router.navigate([`/c/${this.authService.session.company.id}/d/${whereToGo}/${subRoute}`], { queryParamsHandling: 'preserve' })
  //     }
  //   } else{
  //     this.router.navigate([`/c/${this.authService.session.company.id}/d/${whereToGo}`], { queryParamsHandling: 'preserve' })
  //   }
  // }

  getRouterLink(whereToGo: 'referralUrl' |'parties' | 'home' | 'report-list' | 'library' | 'dashboard' | 'quotation' | 'company-role' | 'warehouse' | 'purchase-order' | 'asset-management' | 'services' | 'company-settings' | 'help'): string[] {
    const companyId = this.authService.session.company.id;
    let link = [`/c/${companyId}/d/${whereToGo}`];
    if(whereToGo == 'referralUrl') return link = [this.referralUrl]
    
    if (whereToGo === 'library') {
      if (this.authService.hasAccess(this.authService.companyUserPolicy.View_material_library)) {
        link = [`/c/${companyId}/d/${whereToGo}/material`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_trade_library)) {
        link = [`/c/${companyId}/d/${whereToGo}/trade`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_party_library)) {
        link = [`/c/${companyId}/d/${whereToGo}/party`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_workforce_library)) {
        link = [`/c/${companyId}/d/${whereToGo}/workforce`];
      }
    } else if (whereToGo === 'warehouse') {
      // const warehouseId = this.authService.session.warehouse?.id;
      // if (warehouseId) {
      //   link = [`/c/${companyId}/w/${warehouseId}`];
      // } else {
      //   // Handle case where warehouse ID is not available
      //   // You might need to navigate to a different page or show an error
      //   link = [`/c/${companyId}/add-warehouse`];
      // }
    } else if (whereToGo === 'company-settings') {
      if (this.authService.hasAccess(this.authService.companyUserPolicy.View_company_dashboard)) {
        link = [`/c/${companyId}/d/${whereToGo}/company-details`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_company_party_ledger)) {
        link = [`/c/${companyId}/d/${whereToGo}/business-overview`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_project_dashboard)) {
        link = [`/c/${companyId}/d/${whereToGo}/lock-bak-date`];
      } else if (this.authService.hasAccess(this.authService.companyUserPolicy.View_Purchase_Order_List)) {
        link = [`/c/${companyId}/d/${whereToGo}/bank-details`];
      }
    }

    return link;
  }

  addWarehouse() {
   if(this.authService.session.warehouse?.id){
    this.router.navigate([`/c/${this.authService.session.company.id}/w/${this.authService.session.warehouse?.id}`], { queryParamsHandling: 'preserve' });
   }else{
    const openWarehouse = this.modalService.open(SettingWarehouseComponent, { size: 'lg', backdrop: 'static', backdropClass: 'sales-invoice-modal-backdropClass', windowClass: 'sales-invoice-modal-windowClass' });
    openWarehouse.componentInstance.data = { fromPage: 'warehouseDetails'}
    openWarehouse.result.then(onfulfilled => {
      sessionStorage.setItem("warehouse-created", "1");
      this.router.navigate([`/c/${this.authService.session.company.id}/w/${onfulfilled}`], { queryParamsHandling: 'preserve' });
    }, onReject => {
      //
    })
   }
  }

  deleteProject(project: Project) {
    const index = this.projects.indexOf(project);
    project.delete = 1;
    this.loading = true;
    this.adminService.editProject(project).subscribe(res => {
      this.projects.splice(index, 1);
      this.loading = false;
    });
  }

  edit(project: Project) {
    document.body.classList.add('modal-addition');
    const modalRef = this.modalService.open(ProjectComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.data = project;
    modalRef.result.then(data => {
      project = data;
    }, err => {
      console.log(err);
    });
  }

  // showSubmenu() {
  //   this.showMenu = !this.showMenu;
  // }

  loadMore(n) {
    this.limit = this.limit + n;
    this.projects = this.localStorageService.get('projectsList')
    this.projects = this.projects.slice(0, this.limit);
  }

  openCompanySettingModal() {
    this.modalService.open(SettingCompanyComponent, { size: this.authService.hasAccess(this.authService.companyUserPolicy.Comapny_details) ? 'lg' : 'md', backdrop: 'static' })
  }

  closeSmallSidebar() {
    this.responsiveService.setSmallSidebarStatus(false);
  }

  setProjectAndNavigate(project: Project) {
    if (this.authService.session.company.monkey_patch_my_company_user.company_role_id == 'id1') {
      this.authService.saveProject(project);
      const urlStr = `/../c/${this.authService.session.company.id}/p/${project.id}/${this.authService.getInitialTab()}`;
      this.router.navigate([urlStr]);
    }else{
      this.notificationsService.initiate({
        title: 'Your role is not admin!',
        type: toastTypes.error
      })
    }
  }
  openDemoPage() {
    // add token
    this.authService.addSaleleadToken().subscribe({next: (res) => {
      this.router.navigate(['/book-demo'], {queryParams:{token: res.token, company_id: this.authService.session.company?.id, project_id: this.authService.session.project?.id}})
    }, error:(err)=> {
      console.log(err);
    }})
    // route to demo page
  }

    // ngOnDestroy() {
  //   this.router.events.unsubscribe();
  // }

  //Book Demo + sales lead//

  bookDemo(){
    this.adminService.getSalesLeadToken(this.company.monkey_patch_my_company_user).subscribe({
      next:res=>{
        this.navigateToBookDemoRelative(res.token);
      },
      error:err=>{
        console.log(err)
      }
    })
  }

  navigateToBookDemoRelative(token:string) {
    const queryParams = {
      token: token,
      company_id: this.company.id
    };

    this.router.navigate(['./book-demo'], {
      queryParams: queryParams
    });
  }

  checkWarehouseStatus():boolean{
    if(!this.authService.session.company.is_paid) return false
    if(!this.authService.session.company.is_warehouse_enabled) return false;
    if (new Date(this.authService.session.company.subscription_end_date) <= new Date()) return false;
    if(this.authService.session.company.creator == this.authService.session.profile.id) return true;
    if(this.authService.session?.warehouse?.id) return true;
    return false;
  }
}
